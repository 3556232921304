.video-wrapper.bg-overlay.bg-overlay-gradient:after {
    opacity: 0.3 !important;
    background: linear-gradient(120deg,#008789 50%,#008789c4 100%)!important;
} 


.navbar-stick:not(.navbar-dark) {
    letter-spacing: 0.02rem;
    background: #262b32!important;
}


.navbar-stick .nav-link {

    color: white!important;
}

.navbar-stick .nav-link:hover {

    color: #f653a6;
}

.navbar-stick  button.hamburger {

    color: #ffffff;

}

html{scroll-behavior:smooth; scroll-margin-top: 120px;}

#karriere {scroll-margin-top: 120px;}

body {
    font-size: .95rem;
}

.bg-dark {
    background: #008789!important;
}


.nav-link {
    --bs-nav-link-font-weight: 700;
    font-size: 0.99rem;
	    letter-spacing: 0.04rem;
}



.navbar-brand img {
    padding-bottom: 4px !important;
	
	}
	
	
.page-frame footer {
    margin-top: 0rem !important;
}


.navbar {

    background-color: white;
}

p {
	font-size:1.1rem;
    line-height:1.4rem;
}
.p {
	font-size:1.1rem;
    line-height:1.4rem;
}


.navbar-expand-lg .navbar-brand {
padding-top: 0.4rem !important;
padding-bottom: 0.4rem !important;
}


.icon-list.bullet-soft-primary.bullet-bg i {
    color: white !important;
    background-color: #008789 !important;
}

.logo_mobile_dark
{

display:none;

}

.navbar-stick .logo_mobile_dark
{

display:block;

}

.navbar-stick .logo_mobile
{

display:none;

}




@media (max-width: 990px){

		.swiper-hero, .swiper-hero .swiper-slide {
			height: 400px!important;	 
		}

	.fs-35 {
		font-size:1.1rem !important; 
		    line-height: 1.1rem !important; 
	}
}  




@media (max-width: 767px){

		.swiper-hero, .swiper-hero .swiper-slide {
			height: 340px!important;	 
		}

	.fs-35 {
		font-size:1.1rem !important; 
		    line-height: 0.9rem !important; 
	}
}  


